import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      style={{ transform: 'rotate(18deg)' }}
      {...props}
    >
      <path d="M956.377 540.8c-12.4-38-53.8-58.2-92-45.8l-90.8 30.2-60.6-180 90.8-30.2c38.2-12.4 58.2-53.6 46-91.8-12.4-38-53.8-58.2-92-45.8l-90.8 30.2-31.4-94c-12.4-38-53.8-58.2-92-45.8-38.2 12.4-58.2 53.6-46 91.8l31.4 94-186.8 62.4-31.4-94c-12.4-38-53.8-58.2-92-45.8-38.2 12.4-58.2 53.6-46 91.8l31.4 94-90.6 30c-38.2 12.4-58.2 53.6-46 91.8 10 29 38.2 48 67.2 49.2 13.6 2 24-3.2 115.4-33.6l60.6 180-90.8 30.2c-38 12.4-58.2 53.8-46 91.8 10 29 38.2 48 67.2 49.2 13.6 2 24-3.2 115.4-33.6l31.4 94c11.8 33.8 49.4 58 92 45.8 38.2-12.4 58.2-53.6 46-91.8l-31.4-94 187.2-62.6 31.4 94c11.8 33.8 49.4 58 92 45.8 38.2-12.4 58.2-53.6 46-91.8l-31.4-94 90.8-30.2c38-12 58.2-53.4 45.8-91.4zm-508.2 94.4-60.6-180.4 187-62.6 60.6 180.4-187 62.6z" />
    </svg>
  )
}

export default SVG
